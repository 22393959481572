import React, { useState, Fragment,useEffect } from "react";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

import { BASEURL, APIENDPOINTS } from "../utils/Constants";

import "react-bootstrap-typeahead/css/Typeahead.css";

function AsyncSearchBar(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState("");
  const [allhRef,setAllHref] = useState([])
  const SEARCH_URI = BASEURL + APIENDPOINTS.searchReviews;

  const makeAndHandleRequest = async (query, page) => {
    setIsLoading(true);

    await fetch(SEARCH_URI)
      .then((resp) => resp.json())
      .then((data) => {

        const options = data.reviews.data.map((i) => ({
          website_hostname: i.website_hostname,
        }));

        const newSetOption = options.filter((i) =>
          i.website_hostname.includes(query)
        );
        const filteredSet = newSetOption.filter(
          (v, i, a) =>
            a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
        );

        //for href
        const href = data.reviews.data.map((i) => ({
          website_href: i.website_href,
        
        }));
        setAllHref(href)
        setQuery(query);
        setOptions(filteredSet);
        setIsLoading(false);
      });
  
  };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const searchReviews = (e) => {
    e.preventDefault();

    const href = e.currentTarget.innerText+'/';
    const pre = 'https://'
    const query = allhRef.filter(a=> a.website_href===pre+href);
    let website_href = query.map(a=> a.website_href)
    window.open(`/reviews?hn=${btoa(website_href)}`, "_self")
  };
  const filterBy = () => true;

  return (
    <AsyncTypeahead
      {...props}
      filterBy={filterBy}
      id="async-search"
      isLoading={isLoading}
      labelKey="website_hostname"
      placeholder="Search for a website e.g. google.com"
      maxResults={6}
      minLength={2}
      onSearch={makeAndHandleRequest}
      paginate
      options={options}
      useCache={false}
      renderMenuItemChildren={(option, props) => (
        <Fragment>
          <span onClick={(e) => searchReviews(e)}>
            {option.website_hostname}
          </span>
        </Fragment>
      )}
    />
  );
}

export default AsyncSearchBar;
