import React, { useEffect, useContext } from 'react';
import {Nav, NavDropdown, Navbar, Container, Button, Form, FormControl} from 'react-bootstrap';
import { AppContext } from './../AppContext';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
    BASEURL,
    APIENDPOINTS,
    ENDPOINT,
    errors
} from "../utils/Constants";
import { useSearchParams } from "react-router-dom";
import AsyncSearchBar from "./search"

export default function Header(props) {
    let navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { context, setContext } = useContext(AppContext);
    const [isAuthenticated, setIsAuthenticated] = React.useState('no'); 
    const [name, setName] = React.useState(''); 
    const [profilePic, setProfilePic] = React.useState(null); 
    const [searchItem, setSearchItem] = React.useState(searchParams.get("query")); 

    useEffect(() => {
        let userDetails = JSON.parse(localStorage.getItem('userDetails'));

        if(userDetails && userDetails?.isAuthenticated === 'yes'){
            setIsAuthenticated('yes');
            setName(userDetails.name)

            if(userDetails?.profile_pic)
                setProfilePic(userDetails.profile_pic)
        }

        let urlParam = searchParams.get("hn");
        if(urlParam && urlParam.length > 0) {
            let decodedUrl = atob(urlParam);

            if (decodedUrl.indexOf("http://") === 0 || decodedUrl.indexOf("https://") === 0) {
                let urlTmp = new URL(decodedUrl);
                document.getElementsByClassName('rbt-input-main').value = urlTmp?.hostname;
            }
        }
    }, []);
    
    const handleLogout = (e) => {
        e.preventDefault()
        // remove api token
        axios.post(BASEURL+APIENDPOINTS.logout, {
            userId    : context?.userDetails?.userId,
            api_token : context?.userDetails?.api_token
        })
        .then(response => {
            let data = response.data;

            if(data.status != 200){
                return
            }else{
                // clear cache 
                localStorage.removeItem('userDetails');
                window.open("/","_self");
            }
        })
    }

    const goToLogin = () => {
        navigate('/login');
    }

    return(
        <>
        <Navbar bg="none" variant="light" expand="lg" className="fixed-top shadow-sm pt-3 pb-3 bg-white">
            <Container>
                <Navbar.Brand href="/">
                    <img
                        src="images/logo-v1.png"
                        height="30"
                        className="d-inline-block align-top"
                        alt="G-Reviews"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">

                <Form className="d-flex w-50 ms-4">
                    <div style={{position: 'relative', maxWidth: '400px', width: '100%'}}>
                       <AsyncSearchBar />
                    </div>
                </Form>

                <Nav
                    className=" my-2 my-lg-0 ms-auto"
                    style={{ maxHeight: '100px' }}
                    navbarScroll
                >
                    { isAuthenticated === 'yes' ? 
                    <>
                        <Nav.Link href="/my-reviews">My Reviews</Nav.Link>
                        <NavDropdown eventkey={3} title={<div style={{display: "inline-block"}}>
                            <img 
                                src={!profilePic ? "/images/default-profile-pic.jpeg" : profilePic}
                                alt="profilePic"
                                height="24"
                                width="24"
                                className="rounded-circle ms-1"
                            />
                            </div>} id="basic-nav-dropdown">

                            <NavDropdown.Item href="/edit-profile">Edit profile</NavDropdown.Item>
                            <NavDropdown.Item href="/settings">Settings</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href={void(0)} onClick={(e) => handleLogout(e)}>
                                Logout
                            </NavDropdown.Item>
                        </NavDropdown>
                    </>
                    :
                        <Button variant="outline-primary" onClick={goToLogin}>Login</Button>
                    }
                </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        <div className="mt-5">&nbsp;</div>
        </>
    )
}
