import React, { Fragment, useEffect, createContext, useState, useContext, useMemo } from "react";
import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";
import {AppContext} from './AppContext';

import Layout from "./Layout/Layout";
import Home from "./Landing/Home";
import Login from "./Auth/Login";
import PostReview from "./Reviews/PostReview";
import AllReviews from "./Reviews/AllReviews";
import SearchResults from "./Reviews/SearchResults";
import MyReviews from "./Reviews/MyReviews";
import PrivacyPolicy from "./Legal/PrivacyPolicy";
import Terms from "./Legal/Terms";
import EditProfile from "./User/EditProfile";
import Settings from "./User/Settings";
import ActivateAccount from "./User/ActivateAccount";
import ResetPassword from "./User/ResetPassword";

function App() {
  const [ isAuthenticated, setIsAuthenticated ] = useState('no');
  const [ nextNavScreen, setNextNavScreen ] = useState('AllReviews');
  const [ loading, setLoading] = React.useState(true);
  const [ context, setContext ] = useState();
  
  return (
    <AppContext.Provider value={{context, setContext}}>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/reviews" element={<AllReviews />} />
            <Route path="/my-reviews" element={<MyReviews />} />
            <Route path="/add-review" element={<PostReview />} />
            <Route path="/edit-profile" element={<EditProfile />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/search-results" element={<SearchResults />} />
            <Route path="/login" element={<Login />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/activate-account" element={<ActivateAccount />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Routes>
        </Layout>
      </BrowserRouter> 
    </AppContext.Provider>

  );
}

export default App;





