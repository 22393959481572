import React, { useContext, useEffect } from "react";
import {Container, Row, Col} from 'react-bootstrap';
import Header from "./Header";
import ReviewStats from "../Reviews/ReviewStats";
import { AppContext } from './../AppContext';
import Footer from "./Footer";
import CookieConsent from "react-cookie-consent";

export default function Layout({children}){
    const {context} = useContext(AppContext);
    
    return(
        <>
            <Container fluid className="pl-0 ps-0 pr-0 pe-0">
                <Header/>
                <Row className="pt-1 pb-5" style={{minHeight: '80vh'}}>
                    <Col xs={12} md={12}>
                        {children}
                    </Col>
                    {/* <Col xs={4} md={4} className="text-light bg-dark">
                        right side
                    </Col> */}
                </Row>
            </Container>

            <Container fluid className="bg-dark text-white">
                <Container className="pl-0">
                    <Footer/>
                </Container>

                <CookieConsent
                    location="bottom"
                    buttonText="Got it"
                    cookieName="myAwesomeCookieName2"
                    style={{ background: "#2B373B" }}
                    buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                    expires={150}
                >
                    This website uses cookies to enhance the user experience.{" "}
                </CookieConsent>
            </Container>
        </>
    )
}
let styles = {
    mainContainer: {
    }
}