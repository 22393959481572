import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from './../AppContext';
import {Form, Button, Row, Col, Container, Image} from 'react-bootstrap';
import axios from "axios";
import {
    BASEURL,
    APIENDPOINTS,
    ENDPOINT,
    errors,
    COLOURS
} from "../utils/Constants";
function EditProfile(props) {
    let navigate = useNavigate();
    const [picture, setPicture] = React.useState(null); 
    const [pictureProfile, setPictureProfile] = React.useState(null); 
    const [name, setName] = React.useState(''); 
    const [lastName, setLastName] = React.useState(''); 
    const [email, setEmail] = React.useState(''); 
    const [oldEmail, setOldEmail] = React.useState(localStorage.getItem('userDetails')?.email); 
    const [successMessage, setSuccessMessage] = React.useState(''); 
    const [errorMessage, setErrorMessage] = React.useState(''); 
    
    useEffect(() => {
        if(!localStorage.getItem('userDetails') 
            || JSON.parse(localStorage.getItem('userDetails'))?.isAuthenticated === 'no' 
            || !localStorage.getItem('userDetails').length === 0){
            navigate("/login")
        }

        const getUser = async () => {
            await axios.get(`${BASEURL+APIENDPOINTS.getUser}/${JSON.parse(localStorage.getItem('userDetails')).userId}`)
            .then(response => {
                let data = response.data;
                if(data?.profile_pic && data?.profile_pic.length > 0){
                    setPictureProfile(`${ENDPOINT}/uploads/${data?.profile_pic}`)
                }
                setName(data?.name);
                setLastName(data?.last_name);
                setEmail(data?.email);
            })
            .catch((error) => {
                console.log('ERROR:: ', error)
            });
        }
        getUser();
    }, []);

    const handleChangePic = (event) => {
        let file_name = event.target.files[0]
        setPicture(file_name);   
    }

    const handleSubmitForm = (e) => {
        e.preventDefault();
        const payload = new FormData();
        if(picture)
            payload.append('file', picture)

        payload.append('name', name);
        payload.append('lastName', lastName);
        payload.append('email', email);
        payload.append('userId', JSON.parse(localStorage.getItem('userDetails')).userId);

        axios.post(BASEURL+APIENDPOINTS.updateUser, payload, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
        })
        .then(response => {
            let data = response.data;

            if(data.status != 200){
                setErrorMessage(data?.message)
                console.log(data?.message)
            }else{
                var stored = JSON.parse(localStorage.getItem('userDetails'));
                if(data.fileNameToStore){
                    stored.profile_pic = `${ENDPOINT}/uploads/${data.fileNameToStore}`;
                    setPictureProfile(`${ENDPOINT}/uploads/${data.fileNameToStore}`)
                }
                if(email)
                    stored.email = window.btoa(email)
                
                localStorage.setItem("userDetails", JSON.stringify(stored));
                setSuccessMessage(data.message);
            }
        })
    }

    const sendVerificationEmail = (e) => {
        e.preventDefault();

        axios.post(BASEURL+APIENDPOINTS.sendVerificationEmail, {
            email: email
        })
        .then(response => {
            let data = response.data;

            if(data.status != 200){
                console.log(data?.message)
            }else{
                setSuccessMessage(data.message);
            }
        })
    }
    
    return(
        <Container>            
            <Row className="mt-4">
                <Col xs={12} md={12}>
                    <h5>Edit Profile</h5>
                </Col>
            </Row> 
            <Row style={{display: JSON.parse(localStorage.getItem('userDetails'))?.account_verified === 'no' ? 'block' : 'none'}}>
                <Col xs={12} md={6}>
                    <div className="alert alert-warning" role="alert">
                        Your account is not verified.   
                        <Button variant="link" onClick={sendVerificationEmail}>Send verification link</Button>

                    </div>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col xs={12} md={6}>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicfName">
                            <div>
                                <img
                                    src={pictureProfile ? pictureProfile : "images/profiles_pics/default-profile-pic.jpeg"}
                                    height="100"
                                    className="d-inline-block align-top thumbnail"
                                    alt="Profie"
                                />
                            </div>
                            <input
                                name="file"
                                type="file"
                                className="mt-2 btn-secondary"
                                onChange={(event) => {handleChangePic(event) }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicfName">
                            <Form.Label>First name</Form.Label>
                            <Form.Control type="text" name="name" placeholder="Type here" 
                            value={name}
                            onChange={(e) => {setName(e.target.value) }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicfName">
                            <Form.Label>Last name</Form.Label>
                            <Form.Control type="text" name="lastName" placeholder="Type here" 
                            value={lastName}
                            onChange={(e) => {setLastName(e.target.value) }}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" name="email" placeholder="Type here " 
                             value={email}
                            onChange={(e) => {setEmail(e.target.value) }}
                            />
                        </Form.Group>
                        <small className="errorMessage mb-3" 
                            style={{display: errorMessage ? 'block' : 'none'}}>
                            {errorMessage}
                        </small>
                        <small className="successMessage mb-3" 
                            style={{display: successMessage ? 'block' : 'none'}}>
                            {successMessage}
                        </small>

                        <Button variant="primary" type="button" onClick={handleSubmitForm}>
                            Save
                        </Button>
                    </Form>       
                </Col>
            </Row> 
        </Container>
    )
}

var styles = {

}

export default EditProfile;