import React, { useEffect, useContext } from 'react';
import {Nav, NavDropdown, Navbar, Container, Button, Form, FormControl, Row, Col} from 'react-bootstrap';

export default function Home(props) {
    return(
        <>
        <Container fluid className="pt-5 pb-5 mt-5" style={{background: "url(images/landing/main-bg.png) no-repeat top center", backgroundSize: 'cover'}}>
            <Container>
                <Row className="pb-5 pt-5">
                    <Col xs={12} md={6}>
                        <h1 className="mt-5 bg-white">
                            {`Read & Write Genuine Reviews`}
                        </h1>
                        <p className="mt-3 mb-4 bg-white">
                            A place where you can share your online experiences with others to  make the web a better place.   
                        </p>
                        <Button variant="primary" size="lg" onClick={() => {window.open('/login', "_self")}}>
                            Get Started
                        </Button>
                    </Col>
                    <Col xs={12} md={6} className="ps-5 text-center">
                        <img
                            src="/images/landing/topBanner.png"
                            alt="G-Reviews"
                            height="350"
                        />
                    </Col>
                </Row>
            </Container>
        </Container>

            <Container fluid className="pt-5 pb-5 mt-5" style={{background: "#0258FB"}}>
                <Container>
                    <Row className="pb-5 pt-5 mt-5 mb-5">
                        <Col xs={12}>
                            <h1 className="mt-5 mb-5 text-center text-white" style={{lineHeight: '60px'}}>
                                You can make an impact on everyone's experience 
                                with feedback about websites you visit. 
                            </h1>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container>
                <Row className="pb-5 pt-5 mt-5">
                    <Col xs={12} md={6}>
                        <h1 className="mt-5">Create a review</h1>
                        <p className="mt-3 mb-4">
                            Use the app to leave feedback about useflness and safety.
                            Your review will be seen by others as they encounter the websites 
                            you visited.
                        </p>
                        <Button variant="outline-primary" size="md" onClick={() => {window.open('/login', "_self")}}>
                            Check it out
                        </Button>
                    </Col>
                    <Col xs={12} md={6} className="ps-5">
                        <img
                            src="/images/landing/model.jpg"
                            alt="model"
                            height="300"
                            className="shadow"
                        />
                    </Col>
                </Row>
            </Container>

            <Container fluid className="bg-light pt-5 pb-5 mt-5">
                <Container>
                    <Row className="pb-5 pt-5 mt-5">
                        <Col xs={12} md={7} className="ps-5">
                            <img
                                src="/images/landing/model.jpg"
                                alt="model"
                                height="300"
                                className="shadow"
                            />
                        </Col>
                        <Col xs={12} md={5}>
                            <h1 className="mt-5">Read reviews</h1>
                            <p className="mt-3 mb-4">
                                Access reviews created by other users.
                            </p>
                            <Button variant="outline-primary" size="md" 
                                onClick={() => {window.open('/login', "_self")}}>
                                Check it out
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container>
                <Row className="pb-5 pt-5 mt-5">
                    <Col xs={12} md={6}>
                        <h1 className="mt-5">Hide websites</h1>
                        <p className="mt-3 mb-4">
                            We give you the ability to exclude from future searches websites you find harmful.
                        </p>
                        <Button variant="outline-primary" size="md" 
                            onClick={() => {window.open('/login', "_self")}}>
                            Check it out
                        </Button>
                    </Col>
                    <Col xs={12} md={6} className="ps-5">
                        <img
                            src="/images/landing/model.jpg"
                            alt="model"
                            height="300"
                            className="shadow"
                        />
                    </Col>
                </Row>
            </Container>

            <Container fluid className="bg-light pt-5 pb-5 mt-5">
                <Container>
                    <Row>
                        <Col xs={12} md={12} className="text-center">
                            <h1 className="mt-5 mb-4">How to use the app</h1>
                            <p>You have two options to share your experience: </p>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col xs={12} md={6} className="text-center">
                            <img
                                src="/images/landing/gStoreIcon.png"
                                alt="gStoreIcon"
                                height="100"
                            />
                            <h2 className="mt-4">Get the Chrome Extension</h2>
                            <p>Get the full functionality of Genuine Reviews by adding our Google Chrome Extension</p>
                            <Button variant="outline-primary" 
                                onClick={() => {window.open('https://chrome.google.com/webstore/detail/genuine-reviews-website-r/mheceeceoilbdfhbcjnifkljdllngaal', "_self")}}>
                                    Get the extension
                            </Button>
                        </Col>
                        <Col xs={12} md={6} className="text-center">
                            <img
                                src="/images/landing/g-reviews-icon.png"
                                alt="gStoreIcon"
                                height="100"
                                
                            />
                            <h2 className="mt-4">Use our website</h2>
                            <p>Get additional options by using our website.<br/><br/></p>
                            <Button variant="outline-primary" onClick={() => {window.open('/login', "_self")}}>Open the website</Button>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Container fluid className="pt-5 pb-5 mt-5">
                <Container>
                    <Row>
                        <Col xs={12} md={6} className="ps-5">
                            <img
                                src="/images/landing/about-team.png"
                                alt="model"
                                height="400"
                            />
                        </Col>
                        <Col xs={12} md={6}>
                            <h1 className="mt-5">About Genuine Reviews</h1>
                            <p className="mt-3 mb-4">
                                Our mission is to raise the quality of
                                online experience around the world. We build this platform to help people
                                have a safer and more transparent internet experience.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
}

