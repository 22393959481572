import React, { useEffect, useContext } from 'react';
import {Container, Row, Col} from 'react-bootstrap';

export default function PrivacyPolicy(props) {
    return(
        <>
        <Container>
            <Row className="pb-5 pt-5">
                <Col xs={12}>
                <h1>What is the scope of this Privacy Policy?</h1>
                <p className="privacy-text">
This Privacy Policy is incorporated into G-Reviews’s Terms of Service and License Agreement, located at G-Reviews.com/terms, and applies to the information obtained by us through your use of G-Reviews’s Site, Software, and Services (“Information”) as described in this Policy. Capitalized terms used in this Policy and not otherwise defined shall have the meanings provided for those terms in the Terms of Service.
</p>

<h1>What is G-Reviews’s business model?</h1>
<p className="privacy-text">
G-Reviews offers a free service and paid services. To make money, we encourage the users of our free service to upgrade to one of our paid services. We don’t help third parties to advertise their products to you. We also do not—and will not—sell your information.
</p>
<h2>Information collection and use</h2>
<h3>What Information does G-Reviews collect about me?</h3>
<p className="privacy-text">
When you interact with our Site, Software, and/or Services, we collect information that could be used to identify you (“Personal Data”). Some of the information we collect is stored in a manner that cannot be linked back to you (“Non-Personal Data”).
</p>

<h4>Information you provide us when you create an account</h4>
<p className="privacy-text">
    When you sign up for or use our Services, you voluntarily give us certain Personal Data, including:
</p>
<ul>
    <li>
    <p className="privacy-text">
Your username, email address, and contact and language preferences.  You can stop sharing that information with us at any time by removing G-Reviews’s access to that account.
</p></li>
<li><p className="privacy-text">
Your payment information, if you are a paying customer. This is required to complete a commercial transaction on the Site. We use this information to enable and fulfill your transaction. If you choose to use PayPal® to finalize and pay for your order, you will provide your credit card number directly to PayPal®. The privacy policy of PayPal® will apply to the information you provide on the PayPal® website.
</p></li>
</ul>
<h4>Other Information we collect</h4>
<p className="privacy-text">We collect this information as you use the Site, Software, and/or Services:</p>
<ul>
    <li><p className="privacy-text">
User Content. This consists of all text, documents, or other content or information uploaded, entered, or otherwise transmitted by you in connection with your use of the Services and/or Software. For more information about how we care for and protect your User Content, please see our User Trust Guidelines.
</p></li>
<li><p className="privacy-text">
Names of user contacts in G-Reviews Keyboard (on-device storage only). To make appropriate spelling suggestions, the G-Reviews Keyboard may request or obtain access to your contacts’ names on your device. For instance, G-Reviews will not try to autocorrect names that are in your contacts. These names will be stored solely on your device within the G-Reviews Keyboard app and are never sent to G-Reviews servers.
</p></li>
</ul>
<h4>Automatically collected Information</h4>
<p className="privacy-text">
    Certain data about the devices you use to connect with G-Reviews and your use of the Site, Software, and/or Services are automatically logged in our systems, including:
</p>
<ul>
<li><p className="privacy-text">
Location information. This is the geographic area where you use your computer and mobile devices (as indicated by an Internet Protocol (IP) address or similar identifier) when interacting with our Site, Software, and/or Services.
</p></li>
<li><p className="privacy-text">
Log data. As with most websites and technology services delivered over the internet, our servers automatically collect data when you access or use our Site, Software, and/or Services and record it in log files. This log data may include IP address, browser type and settings, date and time of use, information about browser configuration, language preferences, and cookie data.
</p></li>
<li><p className="privacy-text">
Usage information. This is information about the G-Reviews Site, Software, and/or Services you use and how you use them. We may also obtain data from our third-party partners and service providers to analyze how users use our Site, Software, and/or Services. For example, we will know how many users access a specific page on the Site and which links they clicked on. We use this aggregated information to better understand and optimize the Site.
</p></li>
<li><p className="privacy-text">
Device information. This is data from your computer or mobile device, such as the type of hardware and software you are using (for example, your operating system and browser type), as well as unique device identifiers for devices that are using G-Reviews Software.
</p></li>
<li><p className="privacy-text">
Cookies. Data obtained from cookies is described in the “Does G-Reviews use cookies?” section and in our Cookie Policy.
</p></li>
</ul>
<h4>Information collected from other sources</h4>
<p className="privacy-text">
    We may collect information about you from third parties, such as marketing partners and researchers, where they are legally allowed to share your Information with us. We may combine the Information we receive from other sources with Information we collect from you (or your device) and use it as described in this Privacy Policy.
</p>

<h2>How does G-Reviews use my Information?</h2>
<p className="privacy-text">
    We use, process, and store your Information as necessary to perform our contract with you and for our legitimate business interests, including:
</p>
<ul>
<li><p className="privacy-text">
To help us provide and administer our Site, Software, and/or Services, authenticate users for security purposes, provide personalized user features and access, process transactions, conduct research, develop new features, and improve the features, algorithms, and usability of our Site, Software, and/or Services.
</p></li>
<li><p className="privacy-text">
To communicate with you about your use of our Site, Software, and/or Services, product announcements, and software updates, as well as respond to your requests for assistance, including providing account verification support if you’re having difficulty accessing your account.
</p></li>
<li><p className="privacy-text">
To send you direct marketing emails and special offers about G-Reviews, from which you can unsubscribe at any time. For more information, please see the section titled, “Will G-Reviews send me emails?” If you are located in the European Economic Area (EEA), Switzerland, or the UK, we will only send you marketing information if you consent to us doing so at the time you create your account or any point thereafter.
</p></li>
<li><p className="privacy-text">
To display User Content associated with your account and make sure it is available to you when you use our Services.
</p></li>
<li><p className="privacy-text">
To provide human proofreading services for users who submit User Content in connection with this specific service.
</p></li>
<li><p className="privacy-text">
To calculate aggregate statistics on the number of unique devices using our Site, Software, and/or Services, and to detect and prevent fraud and misuse of those.
</p></li>
</ul>

<h2>Does G-Reviews review User Content?</h2>
<p className="privacy-text">
As a rule, G-Reviews employees do not monitor or view your User Content stored in or transferred through our Site, Software, and/or Services, but it may be viewed if we believe the Terms of Service have been violated and confirmation is required, if we need to do so to respond to your requests for support, if we otherwise determine that we have an obligation to review it as described in the Terms of Service, or to improve our algorithms as described in the User Content section of our Terms of Service. In addition, if you request our human proofreading services, our proofreaders may also read the User Content you submit for this specific service, as necessary to perform our contract with you and for our legitimate business interests. Finally, your Information may be viewed where necessary to protect the rights, property, or personal safety of G-Reviews and its users, or to comply with our legal obligations, such as responding to warrants, court orders, or other legal processes.
</p>

<h1>Information access and disclosure</h1>
<h2>Does G-Reviews share my Information?</h2>
<p className="privacy-text">We only disclose Personal Data to third parties when…</p>
<ul>
    <li><p>
…we use service providers who assist us in meeting business operations needs, including hosting, delivering, and improving our Services. We also use service providers for specific services and functions, including email communication, customer support services, and analytics. These service providers may only access, process, or store Personal Data pursuant to our instructions and to perform their duties to us.
</p></li>
<li><p className="privacy-text">
…we have your explicit consent to share your Personal Data.
</p></li>
<li><p className="privacy-text">
…we believe it is necessary to investigate potential violations of the Terms of Service, to enforce those Terms of Service, or to investigate, prevent, or take action regarding illegal activities, suspected fraud, or potential threats against persons, property, or the systems on which we operate our Site, Software, and/or Services.
</p></li>
<li><p className="privacy-text">
…we determine that the access, preservation, or disclosure of your Personal Data is required by law to protect the rights, property, or personal safety of G-Reviews and users of our Site, Software, and/or Services, or to respond to lawful requests by public authorities, including national security or law enforcement requests.
</p></li>
<li><p className="privacy-text">
…we need to do so in connection with a merger, acquisition, bankruptcy, reorganization, sale of some or all of our assets or stock, public offering of securities, or steps in consideration of such activities (e.g., due diligence). In these cases some or all of your Personal Data may be shared with or transferred to another entity, subject to this Privacy Policy.
</p></li>
</ul>

<p className="privacy-text">
    We may disclose Non-Personal Data publicly and to third parties—for example, in public reports about word usage, to partners under agreement with us, or as part of progress reports we may provide to users.
</p>
<p className="privacy-text">
Through the use of cookies, we help deliver advertisements for relevant G-Reviews products and services to you. For more details on cookies, please see the “Does G-Reviews use cookies?” section below.
</p>
<p className="privacy-text">
G-Reviews does not share your Personal Data with third parties for the purpose of enabling them to deliver their advertisements to you.
</p>

<h2>Does G-Reviews sell or rent my Personal Data?</h2>
<p className="privacy-text">No, G-Reviews does not sell or rent your Personal Data.</p>

<h2>Does G-Reviews use cookies?</h2>
<p className="privacy-text">
Cookies are small text files stored on your device and used by web browsers to deliver personalized content and remember logins and account settings. G-Reviews uses cookies and similar technologies, including tracking pixels and web beacons, to collect usage and analytic data that helps us provide our Site, Software, and/or Services to you, as well as to help deliver ads for relevant G-Reviews products and services to you when you visit certain pages on the Site and then visit certain third-party sites. For more information on cookies and how G-Reviews uses them, please see our Cookie Policy. Our products currently do not respond to Do Not Track requests.
</p>

<h2>How do third-party apps and plugins work?</h2>
<p className="privacy-text">
Some third-party applications and services that work with us may ask for permission to access your Information. Those applications will provide you with notice and request your consent in order to obtain such access or information. Please consider your selection of such applications and services, and your permissions, carefully.
</p>
<p className="privacy-text">
Some third parties’ embedded content or plugins on our Site and/or Software, such as Facebook “Like” buttons, may allow their operators to learn that you have visited the Site, and they may combine this knowledge with other data they have collected about your visits to other websites or online services that can identify you.
</p>
<p className="privacy-text">
Data collected by third parties through these apps and plugins is subject to each parties’ own policies. We encourage you to read those policies and understand how other companies use your data.
</p>

<h2>Will G-Reviews send me emails?</h2>
<p className="privacy-text">
From time to time, we may want to contact you with information about product announcements, software updates, and special offers. We also may want to contact you with information about products and services from our business partners. You may opt out of such communications at any time by clicking the “unsubscribe” link found within G-Reviews emails and changing your contact preferences. All G-Reviews account holders will continue to receive transactional messages related to our Services, even if you unsubscribe from promotional emails.
</p>
<p className="privacy-text">
For EEA, Swiss, and UK users: We only send marketing communications to you with your prior consent. Please see the section “For EEA, Swiss, and UK users” below.
</p>

<h2>Does G-Reviews ever make any of my Personal Data or User Content public?</h2>
<p className="privacy-text">
No, we do not share Personal Data or User Content except in the limited circumstances described in the “Does G-Reviews share my Information?” section of the Policy.
</p>

<h2>Does G-Reviews collect information from children?</h2>
<p className="privacy-text">
Unless your G-Reviews account is part of a G-Reviews for Education (also known as G-Reviews@edu) license, G-Reviews does not knowingly collect personal information from children under the age of 13. If we determine we have collected personal information from a child younger than 13 years of age, we will take reasonable measures to remove that information from our systems. If you are under the age of 13, please do not submit any personal information through the Site, Service, and/or Software. We encourage parents and legal guardians to monitor their children’s internet usage and to help enforce this Policy by instructing their children never to provide personal information through the Site, Service, and/or Software without their permission.
</p>
<p className="privacy-text">
Please note that in some countries the age of digital consent is older than 13. If you are in one of those countries, you must be at least that age to use the Site, Software, and/or Services.
</p>

<h2>Data storage, transfer, retention, and deletion</h2>
<p className="privacy-text">
Where is my Information stored?
</p>
<p className="privacy-text">
Information submitted to G-Reviews will be transferred to, processed, and stored in the United States. When you use the Software on your computing device, User Content you save will be stored locally on that device and synced with our servers. If you post or transfer any Information to or through our Site, Software, and/or Services, you are agreeing to such Information, including Personal Data and User Content, being hosted and accessed in the United States. Please note that the laws of the United States may be different from the privacy laws applicable to the place where you are resident.
</p>
<h2>How secure is my Information?</h2>
<p className="privacy-text">
G-Reviews is committed to protecting the security of your Information and takes reasonable precautions to protect it. However, internet data transmissions, whether wired or wireless, cannot be guaranteed to be 100% secure, and as a result, we cannot ensure the security of Information you transmit to us, including Personal Data and User Content; accordingly, you acknowledge that you do so at your own risk.
</p>
<p className="privacy-text">
We use industry-standard encryption to protect your data in transit and at rest.
</p>
<p className="privacy-text">
Once we receive your data, we protect it on our servers using a combination of technical, physical, and logical security safeguards. The security of the data stored locally in any of our Software installed on your computing device requires that you make use of the security features of your device. We recommend that you take the appropriate steps to secure all computing devices that you use in connection with our Site, Software, and/or Services.
</p>
<p className="privacy-text">
If G-Reviews learns of a security system breach, we may attempt to notify you and provide information on protective steps, if available, through the email address that you have provided to us or by posting a notice on the Site. Depending on where you live, you may have a legal right to receive such notices in writing.
</p>

<h2>How can I delete my Personal Data from G-Reviews?</h2>
<p className="privacy-text">
You can remove your Personal Data from G-Reviews at any time by logging into your account, accessing the Settings page, and then deleting your account. More details can be found here. Please note that, for security reasons, subscribers of our paid services will first be instructed to cancel their subscriptions before they can delete their G-Reviews account.
</p>

<h2>How long is Personal Data retained?</h2>
<p className="privacy-text">
You can remove your Personal Data from G-Reviews at any time by deleting your account as described above. However, we may keep some of your Personal Data for as long as reasonably necessary for our legitimate business interests, including fraud detection and prevention and to comply with our legal obligations including tax, legal reporting, and auditing obligations.
</p>

<h2>What happens if G-Reviews closes my account?</h2>
<p className="privacy-text">
If G-Reviews closes your account due to your violation of the Terms of Service, then you may contact G-Reviews to request deletion of your data. G-Reviews will evaluate such requests on a case by case basis, pursuant to our legal obligations.
</p>
<h2>Will this Privacy Policy ever change?</h2>
<p className="privacy-text">
As G-Reviews evolves, we may need to update this Policy to keep pace with changes in our Site, Software, and Services, our business, and laws applicable to us and you. We will, however, always maintain our commitment to respect your privacy. We will notify you of any material changes that impact your rights under this Policy by email (to your most recently provided email address) or post any other revisions to this Policy, along with their effective date, in an easy-to-find area of the Site, so we recommend that you periodically check back here to stay informed of any changes. Please note that your continued use of G-Reviews after any change means that you agree with and consent to be bound by the new Policy. If you disagree with any changes in this Policy and do not wish your information to be subject to it, you will need to delete your G-Reviews account.
</p> 
                </Col>
            </Row>
        </Container>
        </>
    )
}

