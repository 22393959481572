import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {Row, Col, Container} from 'react-bootstrap';
import axios from "axios";
import {
    BASEURL,
    APIENDPOINTS,
} from "../utils/Constants";

function ActivateAccount(props) {
    let navigate = useNavigate();
    const [code, setCode] = React.useState(''); 
    const [successMessage, setSuccessMessage] = React.useState(''); 
    const [errorMessage, setErrorMessage] = React.useState(''); 
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        let user_id = searchParams.get("user");
        let code = searchParams.get("code");
        let isForwarded = searchParams.get("isForwarded");

        if(isForwarded == 'true'){
            handleSubmitForm(code, user_id);
        }else{
            navigate("/");
        }
    }, [])


    const handleSubmitForm = (code, user_id) => {
        axios.post(BASEURL+APIENDPOINTS.verifyEmail, {
            user_id: user_id,
            code: code
        })
        .then(response => {
            let data = response.data;

            if(data.status != 200){
                console.log(data?.message)
            }else{
                // account_verified
                var stored = JSON.parse(localStorage.getItem('userDetails'));
                if(data.account_verified === 'yes'){
                    stored.account_verified = 'yes';
                    localStorage.setItem("userDetails", JSON.stringify(stored));
                }
                console.log(data)
                navigate("/");
            }
        })        
        .catch((error) => {
            navigate("/", error);
        });
    }

    return(
        <Container>            
            <Row className="mt-4">
                <Col xs={12} md={12}>
                    <h5>Activate Account</h5>
                </Col>
            </Row> 
            <Row className="mt-4">
                <Col xs={12} md={6}>
                    <div class="spinner-border"></div>
                </Col>
            </Row> 
        </Container>
    )
}

var styles = {

}

export default ActivateAccount;