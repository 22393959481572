import React, { useState, useEffect, useContext } from 'react';
import { AppContext } from './../AppContext';
import { useNavigate, useLocation } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import {Form, Button, Row, Col, FloatingLabel, Card, ButtonGroup, Container} from 'react-bootstrap';
import axios from "axios";
import {
    BASEURL,
    APIENDPOINTS,
    errors,
    COLOURS
} from "../utils/Constants";

function PostReview(props) {
    let navigate = useNavigate();
    const location = useLocation();
    const {context, setContext} = useContext(AppContext);
    const [showRegister, setShowRegister] = React.useState(false);
    const [charCount, setCharCount] = React.useState('0');
    const [answerOne, setAnswerOne] = React.useState('');
    const [answerTwo, setAnswerTwo] = React.useState('');
    const [answerThree, setAnswerThree] = React.useState('');
    const [userId, setUserId] = React.useState(0);
    const [websiteHref, setWebsiteHref] = React.useState('');
    const [websiteObj, setWebsiteObj] = React.useState({});
    const [websiteStars, setWebsiteStars] = React.useState(0);
    const [reviewComment, setReviewComment] = React.useState('');
    const [reviewQOne, setReviewQOne] = React.useState('');
    const [reviewQTwo, setReviewQTwo] = React.useState('');
    const [reviewQThree, setReviewQThree] = React.useState('');
    const [websiteHostnameErr, setWebsiteHostnameErr] = React.useState(false);
    const [backendErr, setBackendErrErr] = React.useState('');
    const [disableBtn, setDisableBtn] = React.useState(false);
    const [websiteValid, setWebsiteValid] = React.useState(true);
    const [loading, setLoading] = React.useState(true);
    const [savedReviewId, setSavedReviewId] = React.useState();

console.log(location.state)

    useEffect(() => {
        const getData = async () => {
            if(localStorage.getItem('userDetails') && JSON.parse(localStorage.getItem('userDetails')).isAuthenticated === 'yes'){
                setUserId(JSON.parse(localStorage.getItem('userDetails')).userId);
            }else{
                navigate("/login")
            }

            // if(location?.state?.websiteObj){
            setWebsiteObj(location.state?.websiteObj);
            // }

            if(location.state?.newRating){
                setWebsiteStars(parseFloat(location.state.newRating))
            }
            
            if(!websiteStars || websiteStars === 0){
                setDisableBtn(false);
            }else{
                setDisableBtn(true);
            }

            if(location.state?.isEditReview && location.state?.userReviewedData){
                setWebsiteStars(Math.floor(location.state.userReviewedData?.review_stars));
                setReviewComment(location.state.userReviewedData?.review_comment);
                setAnswerOne(location.state.userReviewedData?.review_question_one_answer);
                setAnswerTwo(location.state.userReviewedData?.review_question_two_answer);
                setAnswerThree(location.state.userReviewedData?.review_question_three_answer);
                setSavedReviewId(location.state.userReviewedData?.id);
            }
        }
        getData();
        setLoading(false);
    }, [])
    
    const ratingChanged = (newRating) => {
        setWebsiteStars(newRating)
        if(!newRating || newRating === 0){
            setDisableBtn(false);
        }else{
            setDisableBtn(true);
        }   
    };
      
    const handleSetCharCount = (e) => {
        if(e.target.value.length >= 500){
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const handleMoreQuestion = (answer, question) => {
        if(question === 'q1'){
            setAnswerOne(answer);
        }
        
        if(question === 'q2'){
            setAnswerTwo(answer);
        }

        if(question === 'q3'){
            setAnswerThree(answer);
        }
    }
    
    const handlePostReview = (e) => {
        e.preventDefault();

        let csrf_token = ''; 
        let payload = {
            'user_id': userId,
            'website_href': websiteObj.href,
            'website_hostname': websiteObj.host,
            'review_stars': websiteStars,
            'review_comment': reviewComment,
            'review_question_one': reviewQOne,
            'review_question_one_answer': answerOne,
            'review_question_two': reviewQTwo,
            'review_question_two_answer': answerTwo,
            'review_question_three': reviewQThree,
            'review_question_three_answer': answerThree,
            '_token': csrf_token
        }

        if(savedReviewId && savedReviewId > 0){
            payload.reviewId = savedReviewId
        }

        axios.post(BASEURL+APIENDPOINTS.storeReview, {
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRFToken': '',
            },
            ...payload
        })
        .then(response => {
            let data = response.data;

            if(data.status !== 200){
                setBackendErrErr(data?.message);
                return
            }else{
                setBackendErrErr('');
                // context?.isEditReview = false;
                cancelPostReview();
                setSavedReviewId(data?.reviewId)
            }
        })
        .catch((error) => {
            console.log('ERROR:: ', error)
        });
    }

    const cancelPostReview = () => {
        navigate(`/reviews?hn=${btoa(websiteObj.origin)}`);
    }

    return(
        <Container>
            <div style={{display: loading ? 'none' : 'block'}}>
                <Row className="mt-3">
                    <Col xs={12} md={12}>
                        <h5>Rate this website</h5>
                        <p>Leave a review below to help others</p>
                    </Col>
                </Row>
                <Form className="leaveReviewStars">
                    <ReactStars
                        count={5}
                        onChange={ratingChanged}
                        size={28}
                        value={websiteStars}
                        isHalf={false}
                        activeColor={COLOURS.reviewStars}
                        emptyIcon={<i className="far fa-star"></i>}
                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                        filledIcon={<i className="fa fa-star"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                    />

                    <FloatingLabel 
                        controlId="floatingTextarea2" 
                        label="Describe your experience (optional)"
                        className="mt-2"
                    >
                        <Form.Control
                            as="textarea"
                            style={{ height: '70px' }}
                            onKeyPress={e => handleSetCharCount(e)}
                            value={reviewComment}
                            onChange={e => {setCharCount(e.target.value.length); setReviewComment(e.target.value);}}
                        />
                    </FloatingLabel>
                    <p style={{textAlign: 'right', fontSize: '12px'}} className="text-secondary">{charCount}/500</p>
                    
                    <p>Tell us more (optional)</p>
                    <Card className="w-100">
                        <Card.Body>
                            <Card.Text>
                                Is this website’s content accurate? 
                            </Card.Text>
                            <ButtonGroup aria-label="Question group one">
                                <Button size="sm" 
                                    variant={answerOne === 'yes' ? "secondary" : "outline-secondary"} 
                                    className="me-3 rounded-pill"
                                    onClick={(e) => handleMoreQuestion("yes", "q1")}
                                >
                                    Yes
                                </Button>
                                <Button size="sm" 
                                    variant={answerOne === 'other' ? "secondary" : "outline-secondary"}
                                    className="me-3 rounded-pill"
                                    onClick={(e) => handleMoreQuestion("other", "q1")}
                                >
                                    Don't know
                                </Button>
                                <Button size="sm" 
                                    variant={answerOne === 'no' ? "secondary" : "outline-secondary"} 
                                    className="me-3 rounded-pill"
                                    onClick={(e) => handleMoreQuestion("no", "q1")}
                                >
                                    No
                                </Button>
                            </ButtonGroup>
                        </Card.Body>
                    </Card>

                    <Card className="w-100 mt-3">
                        <Card.Body>
                            <Card.Text>
                                Is this website safe, legit and reliable?
                            </Card.Text>
                            <ButtonGroup aria-label="Question group two">
                                <Button size="sm" 
                                    variant={answerTwo === 'yes' ? "secondary" : "outline-secondary"}
                                    className="me-3 rounded-pill"
                                    onClick={(e) => handleMoreQuestion("yes", "q2")}
                                >
                                    Yes
                                </Button>
                                <Button size="sm" 
                                    variant={answerTwo === 'other' ? "secondary" : "outline-secondary"}
                                    className="me-3 rounded-pill"
                                    onClick={(e) => handleMoreQuestion("other", "q2")}
                                >
                                    Don't know
                                </Button>
                                <Button size="sm" 
                                    variant={answerTwo === 'no' ? "secondary" : "outline-secondary"} 
                                    className="me-3 rounded-pill"
                                    onClick={(e) => handleMoreQuestion("no", "q2")}
                                >
                                    No
                                </Button>
                            </ButtonGroup>
                        </Card.Body>
                    </Card>

                    <Card className="w-100 mt-3">
                        <Card.Body>
                            <Card.Text>
                                Is this website advertising fake products, services, etc?
                            </Card.Text>
                            <ButtonGroup aria-label="Question group three">
                                <Button size="sm" 
                                    variant={answerThree === 'yes' ? "secondary" : "outline-secondary"}
                                    className="me-3 rounded-pill"
                                    onClick={(e) => handleMoreQuestion("yes", "q3")}
                                >
                                    Yes
                                </Button>
                                <Button size="sm" 
                                    variant={answerThree === 'other' ? "secondary" : "outline-secondary"}
                                    className="me-3 rounded-pill"
                                    onClick={(e) => handleMoreQuestion("other", "q3")}
                                >
                                    Don't know
                                </Button>
                                <Button size="sm" 
                                    variant={answerThree === 'no' ? "secondary" : "outline-secondary"}
                                    className="me-3 rounded-pill"
                                    onClick={(e) => handleMoreQuestion("no", "q3")}
                                >
                                    No
                                </Button>
                            </ButtonGroup>
                        </Card.Body>
                    </Card>
                    
                    <p className="errorMessage" 
                        style={{display: !websiteValid ? 'block' : 'none'}}>
                        Invalid website URL. Please reload the page or use a different URL.
                    </p>
                    
                    <p className="errorMessage" 
                        style={{display: websiteHostnameErr ? 'block' : 'none'}}>
                        Error processing the URL for this website.
                    </p>

                    <p className="errorMessage" 
                        style={{display: backendErr?.length > 1 ? 'block' : 'none'}}>
                        {backendErr}
                    </p>

                    <Button variant="primary" type="button" className="w-25 mt-4 me-4" 
                        disabled={!disableBtn ? true : ""}
                        onClick={(e) => handlePostReview(e)}
                        title={disableBtn === 'false' ? "Review required" : ""}>
                        Post
                    </Button>

                    <Button variant="outline-secondary" type="button" className="w-25 mt-4" onClick={() => cancelPostReview()}>
                        Cancel
                    </Button>
                </Form>
            </div>
        </Container>
    )
}


export default PostReview;