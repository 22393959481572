import React, { useEffect, useContext } from 'react';
import {Nav, NavDropdown, Navbar, Container, Button, Form, FormControl, Row, Col} from 'react-bootstrap';

export default function Footer(props) {
    return(
        <>
            <Row className="pb-4 pt-5 pl-0">
                <Col xs={12} md={4} className="pl-0">
                    <Nav className="flex-column pl-0">
                        <Nav.Link href="/terms" className="text-white">Terms of Service</Nav.Link>
                        <Nav.Link href="/privacy-policy" className="text-white">Privacy Policy</Nav.Link>
                    </Nav>
                </Col>
                <Col xs={12} md={4} className="">
                <Nav className="flex-column pl-0">
                        <Nav.Link href="/about" className="text-white">About</Nav.Link>
                        <Nav.Link href="/team" className="text-white">Team</Nav.Link>
                    </Nav>
                </Col>
            </Row>

            <Row className="pt-3 border-top border-secondary">
                <Col xs={12} md={4}>
                    <p className="text-secondary">© 2022 Genuine Reviews Inc</p>
                </Col>
            </Row>
        </>
    )
}

