import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from './../AppContext';
import {Form, Button, Row, Col, Container} from 'react-bootstrap';
import ReactStars from "react-rating-stars-component";
import { useSearchParams } from "react-router-dom";
import Pagination from "react-js-pagination";
import axios from "axios";

import {
    BASEURL,
    APIENDPOINTS,
    errors,
    COLOURS
} from "../utils/Constants";

import UserReview from "./UserReview";
import ReviewStats from "./ReviewStats";

function AllReviews(props) {
    let navigate = useNavigate();
    const {context, setContext} = useContext(AppContext);
    const [answer, setAnswer] = React.useState('all');
    const [userId, setUserId] = React.useState('');
    const [websiteObj, setWebsiteObj] = React.useState({});
    const [websiteHref, setWebsiteHref] = React.useState('');
    const [websiteHostname, setWebsiteHostname] = React.useState('');
    const [websiteStars, setWebsiteStars] = React.useState(0);
    const [backendErr, setBackendErrErr] = React.useState('');
    const [websiteHostnameErr, setWebsiteHostnameErr] = React.useState(false);
    const [websiteValid, setWebsiteValid] = React.useState(false);
    const [isReviewed, setIsReviewed] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [allReviews, setAllReviews] = React.useState([]);
    const [filteredReviews, setFilteredReviews] = React.useState([]);
    const [userReviewedData, setUserReviewedData] = React.useState({}); 
    const [paginationData, setPaginationData] = React.useState({}); 
    const [isAuthenticated, setIsAuthenticated] = React.useState('no'); 
    const [searchParams, setSearchParams] = useSearchParams();
    
    useEffect(() => {
        const getData = async () => {
            // reviews?hn=
            let urlParam = searchParams.get("hn");
            let decodedUrl = atob(urlParam);
            let objUrl = null;

            if (decodedUrl.indexOf("http://") === 0 || decodedUrl.indexOf("https://") === 0) {
                objUrl = new URL(decodedUrl);
                setWebsiteObj(objUrl)
            }

            if(localStorage.getItem('userDetails') && JSON.parse(localStorage.getItem('userDetails')).isAuthenticated === 'yes'){
                setUserId(JSON.parse(localStorage.getItem('userDetails')).userId);
                setIsAuthenticated('yes');
                checkUserReviewed(objUrl.hostname)
            }
            
            await getAllReviews(objUrl.hostname)
        }

        getData();
    }, [])

    useEffect(() => {
        if(isAuthenticated === 'yes'){
            checkUserReviewed(websiteObj.hostname)
        }
    }, [])

    const checkUserReviewed = async (hostname) => {
        await axios.get(BASEURL + APIENDPOINTS.checkUserReviewed, {
            params: {
                user_id: userId,
                website_hostname: hostname
            }
        })
        .then(response => {
            let data = response.data;
            if(data.status !== 200){
                console.log(data?.message);
            }else{
                // setBackendErrErr('');
                setIsReviewed(data.isReviewed);
                setUserReviewedData(data?.reviewData);
                setWebsiteStars(data?.reviewData?.review_stars);
            }
        })
        .catch((error) => {
            console.log('ERROR:: ', error)
        });
    };

    const getAllReviews = async (hostname, pageNumber = 1) => {
        await axios.get(BASEURL + APIENDPOINTS.getAllReviews, //+'?page='+pageNumber, 
        {
            params: {
                user_id: userId ? userId : '',
                website_hostname: hostname,
                page: pageNumber
            }
        })
        .then(response => {
            let data = response.data;

            if(data.status !== 200){
                // console.log('400 ');
            }else{
                setAllReviews(JSON.parse(JSON.stringify(data?.reviews?.data)));
                setPaginationData({
                    current_page: data.reviews.current_page,
                    from: data.reviews.from,
                    to: data.reviews.to,
                    per_page: data.reviews.per_page,
                    next_page_url: data.reviews.next_page_url,
                    first_page_url: data.reviews.first_page_url,
                    allPagination: data.allPagination
                });
            }
            setLoading(false)
        })
        .catch((error) => {
            setLoading(false)
            console.log('ERROR:: ', error)
        });
    };

    const handleFilterPick = (e, filterNum) => {
        setAnswer(filterNum);
    };

    const ratingChanged = (newRating) => {
        setWebsiteStars(newRating)
        navigate("/add-review", {state:{newRating:newRating, websiteObj: {
            origin: websiteObj.origin,
            host: websiteObj.hostname,  
            href: websiteObj.href
        } }});
    };

    const openReviewScreen = (isEdit) => {
        navigate("/add-review", { state: {
            isEditReview: isEdit, 
            websiteObj: {
                origin: websiteObj.origin,
                host: websiteObj.hostname,  
                href: websiteObj.href
            },
            userReviewedData: isEdit === true ? userReviewedData : ''
        }});
    };

    const deleteReview = (reviewId) => {        
        axios.post(BASEURL+APIENDPOINTS.deleteReview, {
            reviewId: reviewId
        })
        .then(response => {
            let data = response.data;

            if(data.status !== 200){
                setBackendErrErr(data?.message);
                return
            }else{
                checkUserReviewed(websiteHostname, userId);
                setWebsiteStars(0);
                setIsReviewed(false);
                setUserReviewedData(null);
            }
        })
        .catch((error) => {
            console.log('ERROR:: ', error)
        });
    };

    // if (loading) {
    //     return <div>Loading...</div>;
    // }

    return(
        <Container className="mt-5">            
            <ReviewStats 
                websiteHostname={websiteObj.hostname}
                websiteHref={websiteObj.href}
                totalReviewsNum={allReviews.length}
                openReviewScreen={openReviewScreen}
            />
            <Row className="border-bottom pt-2 pb-3">
                <Col xs={2} md={2} >
                    <Button size="sm w-100" 
                        variant={answer === 'all' ? "secondary" : "outline-secondary"}
                        className="rounded-pill"
                        onClick={(e) => handleFilterPick(e, "all")}
                    >
                        All 
                    </Button>
                </Col>
                <Col xs={2} md={2} >
                    <Button size="sm w-100" 
                        variant={answer === '5' ? "secondary" : "outline-secondary"}
                        className="rounded-pill"
                        onClick={(e) => handleFilterPick(e, "5")}
                    >
                        5 <i className="fa fa-star"></i>
                    </Button>
                </Col>
                <Col xs={2} md={2} >
                    <Button size="sm w-100" 
                        variant={answer === '4' ? "secondary" : "outline-secondary"}
                        className="rounded-pill"
                        onClick={(e) => handleFilterPick(e, "4")}
                    >
                        4 <i className="fa fa-star"></i>
                    </Button>
                </Col>
                <Col xs={2} md={2} >
                    <Button size="sm w-100" 
                        variant={answer === '3' ? "secondary" : "outline-secondary"}
                        className="rounded-pill"
                        onClick={(e) => handleFilterPick(e, "3")}
                    >
                        3 <i className="fa fa-star"></i>
                    </Button>
                </Col>
                <Col xs={2} md={2} >
                    <Button size="sm w-100" 
                        variant={answer === '2' ? "secondary" : "outline-secondary"}
                        className="rounded-pill"
                        onClick={(e) => handleFilterPick(e, "2")}
                    >
                        2 <i className="fa fa-star"></i>
                    </Button>
                </Col>
                <Col xs={2} md={2} >
                    <Button size="sm w-100" 
                        variant={answer === '1' ? "secondary" : "outline-secondary"}
                        className="rounded-pill"
                        onClick={(e) => handleFilterPick(e, "1")}
                    >
                        1 <i className="fa fa-star"></i>
                    </Button>
                </Col>
            </Row>

            { (context?.userDetails?.isAuthenticated === 'yes' || isAuthenticated === 'yes' )? 
                <>
                    <Row className="mt-4">
                    {
                    isReviewed ? 
                        <Col xs={12} md={12}>
                            <h5>Your review</h5>
                        </Col>
                    :
                        <Col xs={12} md={12}>
                            <h5>Rate and review</h5>
                            <p>Share your experience to help others</p>
                        </Col>
                    }
                    </Row>

                    <Row className="border-bottom pb-4">
                        <Col xs={12} md={12} className={!isReviewed ? "leaveReviewStars" : ''}>
                            {isReviewed ? 
                            <UserReview 
                                name={userReviewedData?.name}
                                profile_pic={userReviewedData?.profile_pic}
                                review_stars={Number(userReviewedData?.review_stars)}
                                updated_at={userReviewedData.updated_at}
                                review_comment={userReviewedData?.review_comment}
                                is_reviewed={isReviewed}
                                reviewId={userReviewedData?.id}
                                deleteReview={deleteReview}
                                user_id={userReviewedData?.user_id}
                                logged_user_id={userId}
                            /> 
                            : 
                            <ReactStars
                                count={5}
                                onChange={ratingChanged}
                                size={28}
                                value={Number(websiteStars)}
                                isHalf={false}
                                activeColor={COLOURS.reviewStars}
                                emptyIcon={<i className="far fa-star"></i>}
                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                filledIcon={<i className="fa fa-star"></i>}
                                fullIcon={<i className="fa fa-star"></i>}
                            />
                            }
                            {/* <p className="errorMessage" 
                                style={{display: websiteValid ? 'block' : 'none'}}>
                                Invalid website URL. Please reload the page or use a different URL.
                            </p>
                             */}
                            <p className="errorMessage" 
                                style={{display: websiteHostnameErr ? 'block' : 'none'}}>
                                Error processing the URL for this website.
                            </p>

                            <p className="errorMessage" 
                                style={{display: backendErr?.length > 1 ? 'block' : 'none'}}>
                                {backendErr}
                            </p>
                            { !isReviewed ? 
                                <Button variant="link" 
                                    className="mt-0 ps-0"
                                    size="md"
                                    onClick={() => openReviewScreen(false) }
                                >
                                    Leave a review
                                </Button>
                            :
                                <Button variant="link" 
                                    className="mt-0 ps-0"
                                    size="md"
                                    onClick={() => openReviewScreen(true) }
                                >
                                    Edit your review
                                </Button>
                            }
                        </Col>
                    </Row>
                    </>
                : null
            }

            {   
                allReviews && allReviews.length > 0 ?
                    allReviews.filter(review => {
                        if(answer === 'all'){
                            return allReviews
                        }else{
                            return review.review_stars == answer
                        }
                    })
                    .map((review, index) => {
                        return ( <UserReview 
                            profile_pic={review?.profile_pic}
                            name={review.name}
                            review_stars={review.review_stars}
                            updated_at={review.updated_at}
                            review_comment={review.review_comment}
                            user_id={review.user_id}
                            logged_user_id={userId}
                            is_reviewed={isReviewed}
                            reviewId={review.id}
                            isAuthenticated={(context?.isAuthenticated === 'yes' || isAuthenticated === 'yes' ) ? 'yes' : 'no'}
                        />)
                    })
                :
                <p className="mt-3">No reviews found.</p>           
            }   
            {   
                paginationData && paginationData.allPagination > paginationData.per_page ?
                <div className="mt-3">
                    <Pagination
                        activePage={paginationData.current_page}
                        itemsCountPerPage={paginationData.per_page}
                        totalItemsCount={paginationData.allPagination}
                        pageRangeDisplayed={5}
                        onChange={(pageNumber) => {
                            getAllReviews(websiteHostname, userId ? userId : '', pageNumber)
                        }}
                        itemClass="page-item"
                        linkClass="page-link"
                    />
                </div>
                : null       
            }   

        </Container>
    )
}

var styles = {

}

export default AllReviews;