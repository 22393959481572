import React, { useState, useEffect, useContext } from 'react';
import {Form, Button, Row, Col, Container, Stack, Alert} from 'react-bootstrap';
import PostReview from "./../Reviews/PostReview";
import { AppContext } from './../AppContext';
import axios from "axios";
import { useNavigate } from "react-router-dom";

import {
    BASEURL,
    APIENDPOINTS,
    ENDPOINT,
    errors
} from "../utils/Constants";

export default function Login({props}) {
    let navigate = useNavigate();
    const {context, setContext} = useContext(AppContext);
    const [showRegister, setShowRegister] = React.useState(false);
    const [showLogin, setShowLogin] = React.useState(true);
    const [email, setEmail] = React.useState('');
    const [emailErrLogin, setEmailErrLogin] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [passwordErrLogin, setPasswordErrLogin] = React.useState(false);
    const [name, setName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [nameErrLogin, setNameErrLogin] = React.useState(false);
    const [lNameErrLogin, setLNameErrLogin] = React.useState(false);
    const [emailReg, setEmailReg] = React.useState('');
    const [emailErrReg, setEmailErrReg] = React.useState(false);
    const [passwordReg, setPasswordReg] = React.useState('');
    const [passwordErrReg, setPasswordErrReg] = React.useState(false);
    const [errLogin, setErrLogin] = React.useState(false);
    const [errLoginMessage, setErrLoginMessage] = React.useState();
    const [errReg, setErrReg] = React.useState(false);
    const [errRegMessage, setErrRegMessage] = React.useState();

    const [forgotPassword, setForgotpassword] = React.useState(false);
    const [alert,setAlert] = React.useState(false);

    useEffect(() => {
        // check if the user is logged in already
        if(context?.userDetails?.isAuthenticated === 'no'){
            navigate('/')
        }
    }, []);

    const handleShowRegister = () => {
        setShowRegister(true);
        setShowLogin(false);
    }

    const handleShowLogin = () => {
        setShowLogin(true);
        setShowRegister(false);
    }

    const handleLogin = (e) => {
        e.preventDefault();

        if(!email || email.length < 1){
            setEmailErrLogin(true)
            return 
        }else{
            setEmailErrLogin(false)
        }

        if(!password || password.length < 1){
            setPasswordErrLogin(true)
            return
        }else{
            setPasswordErrLogin(false)
        }

        axios.post(BASEURL+APIENDPOINTS.login, {
            email    : email,
            password : password
        })
        .then(response => {
            let data = response.data;

            if(data.status != 200){
                setErrLogin(true);
                setErrLoginMessage(data.message)
                return
            }else{
                setErrLogin(false);
                data.email = window.btoa(data.email);
                data.isAuthenticated = 'yes';
                if(data?.profile_pic){
                    data.profile_pic = `${ENDPOINT}/uploads/${data?.profile_pic}`
                }
                localStorage.setItem('userDetails', JSON.stringify(data));
                window.open("/","_self")
            }
        })
        .catch((error) => {
            console.log('ERROR:: ', error)
        });
    }

    // register new user
    const handleRegister = (e) => {
        e.preventDefault();

        if(!name || name.length < 1){
            setNameErrLogin(true)
            return
        }else{
            setNameErrLogin(false)
        }

        if(!lastName || lastName.length < 1){
            setLNameErrLogin(true)
            return
        }else{
            setLNameErrLogin(false)
        }

        if(!emailReg || emailReg.length < 1){
            setEmailErrReg(true)
            return 
        }else{
            setEmailErrReg(false)
        }

        if(!passwordReg || passwordReg.length < 1){
            setPasswordErrReg(true)
            return
        }else{
            if(passwordReg.length < 6){
                setPasswordErrReg(true)
                return
            }
            setPasswordErrReg(false)
        }

        axios.post(BASEURL+APIENDPOINTS.register, {
            email    : emailReg,
            password : passwordReg,
            name     : name,
            lastName : lastName
        })
        .then(response => {
            let data = response.data;

            if(data.status != 200){
                setErrReg(true);
                setErrRegMessage(data.message)
                return
            }else{
                setErrLogin(false);
                let data = response.data;

                if(data.status != 200){
                    setErrLogin(true);
                    setErrLoginMessage(data.message)
                    return
                }else{
                    setErrLogin(false);
                    data.email = window.btoa(data.email);
                    data.isAuthenticated = 'yes';
                    if(data?.profile_pic){
                        data.profile_pic = `${ENDPOINT}/uploads/${data?.profile_pic}`
                    }
                    localStorage.setItem('userDetails', JSON.stringify(data));
                    window.open("/","_self")
                }
            }
        })
        .catch((error) => {
            console.log('ERROR:: ', error)
        });
    }

    const handleForgotPassword = () => {
        setForgotpassword(true);
        setShowRegister(true);
        setShowLogin(false);
    }
    
    const resetPassword = (e) => {
        e.preventDefault()
        axios.post(BASEURL+APIENDPOINTS.forgotPasswordGenerateCode,{
            email: emailReg
        })
        .then(response => {
            let data = response.data;
            setAlert(true)
        })
        .catch((error) => {
            console.log('ERROR:: ', error)
        });
    }

    return(
        <Container className="mt-5">
        {!showRegister ? 
            <div>
                <Row className="mt-4 mb-4">
                    <Col xs={6} md={3}>
                        <h3>Sign in</h3>
                    </Col>
                    <Col xs={6} md={3}>
                        <Button variant="link" className="p-0 float-end" 
                            onClick={handleShowRegister}>
                            {`I don't have an account`}
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} md={6}>
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="text" placeholder="Email" 
                                    className={emailErrLogin ? "border-danger" : ''}
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                />
                                <p className="errorMessage" 
                                    style={{display: emailErrLogin ? 'block' : 'none'}}>
                                    {errors.requiredField}
                                </p>
                            </Form.Group>
                            
                            <Form.Group className="mb-1" controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="*****" 
                                    className={passwordErrLogin ? "border-danger" : ''}
                                    onChange={e => setPassword(e.target.value)}
                                    value={password}
                                />
                                <p className="errorMessage" 
                                    style={{display: passwordErrLogin ? 'block' : 'none'}}>
                                    {errors.requiredField}
                                </p>
                            </Form.Group>
                            <p className="errorMessage" 
                                style={{display: errLogin ? 'block' : 'none'}}>
                                {errLoginMessage}
                            </p>

                            <Button variant="link" className="p-0"
                                onClick={handleForgotPassword}
                            >
                                Forgot password?
                            </Button>

                            <Button variant="primary" type="button" className="w-100 mt-4"
                                onClick={handleLogin}
                            >
                                Login
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </div>
        : 
            forgotPassword ? (
                <Form >
                    <Col xs={6} md={6}>
                        <Form.Group controlId="formBasicEmail">
                            {alert &&  <Alert variant={'success'}>
                                Check your email for the reset link.
                            </Alert>}
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Email"
                                className={emailErrReg ? "border-danger" : ""}
                                
                                value={emailReg}
                                onChange={(e) => setEmailReg(e.target.value)}
                            />
                            <p
                                className="errorMessage"
                                style={{ display: emailErrReg ? "block" : "none" }}
                            >
                                {errors.requiredField}
                            </p>
                        </Form.Group>
                        <Stack gap={2} >
                            <Button
                                variant="primary"
                                type="submit"
                                className="w-100 mt-4"
                                onClick={resetPassword}
                            >
                                Reset Password
                            </Button>
                        </Stack>
                    </Col>
                </Form>
            ) 
            :
            <div>
                <Row className="mt-4 mb-4">
                    <Col xs={6} md={3}>
                        <h3>Register in</h3>
                    </Col>
                    <Col xs={6} md={3}>
                        <Button variant="link" className="p-0 float-end"
                            onClick={handleShowLogin}>
                            I have an account
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} md={6}>
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control type="text" placeholder="Type here" 
                                    className={nameErrLogin ? "border-danger" : ''}
                                    onChange={e => setName(e.target.value)}
                                    value={name}
                                />
                                <p className="errorMessage" 
                                    style={{display: nameErrLogin ? 'block' : 'none'}}>
                                    {errors.requiredField}
                                </p>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control type="text" placeholder="Type here" 
                                    className={lNameErrLogin ? "border-danger" : ''}
                                    onChange={e => setLastName(e.target.value)}
                                    value={lastName}
                                />
                                <p className="errorMessage" 
                                    style={{display: lNameErrLogin ? 'block' : 'none'}}>
                                    {errors.requiredField}
                                </p>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Type here" 
                                    className={emailErrReg ? "border-danger" : ''}
                                    onChange={e => setEmailReg(e.target.value)}
                                    value={emailReg}
                                />
                                <p className="errorMessage" 
                                    style={{display: emailErrReg ? 'block' : 'none'}}>
                                    {errors.requiredField}
                                </p>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" 
                                    placeholder="*****"
                                    className={passwordErrReg ? "border-danger" : ''}
                                    onChange={e => setPasswordReg(e.target.value)}
                                    value={passwordReg}
                                />
                                <p className="errorMessage" 
                                    style={{display: passwordErrReg ? 'block' : 'none'}}>
                                    {errors.validationPassReg}
                                </p>
                            </Form.Group>

                            <p className="errorMessage" 
                                style={{display: errReg ? 'block' : 'none'}}>
                                {errRegMessage}
                            </p>

                            <Button variant="primary" type="button" className="w-100 mt-4"
                                onClick={handleRegister}
                            >
                                Register
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </div>
        }
        </Container>
    )
}

var styles = {
    hideRegister: {
      display: "none"
    },
    showRegister: {
        display: "block"
    },
    hideLogin: {
        display: "none"
    },
    showLogin: {
        display: "block"
    },
  
  }