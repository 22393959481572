import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from './../AppContext';
import {Form, Button, Row, Col, Container} from 'react-bootstrap';
import ReactStars from "react-rating-stars-component";
import { useSearchParams } from "react-router-dom";
import Pagination from "react-js-pagination";
import axios from "axios";
import {
    BASEURL,
    APIENDPOINTS,
    errors,
    COLOURS
} from "../utils/Constants";

import UserReview from "./UserReview";
import ReviewStats from "./ReviewStats";





function AllReviews(props) {
    let navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const {context, setContext} = useContext(AppContext);
    const [loading, setLoading] = React.useState(true);
    const [allReviews, setAllReviews] = React.useState([]);
    const [paginationData, setPaginationData] = React.useState({}); 
    const [searchTerm, setSearchTerm] = React.useState(); 
    const [host, setHost] = React.useState(); 
    const [allPagination, setAllPagination] = React.useState();
    
    


    useEffect(() => {
            let urlParam = searchParams.get("query");
            setSearchTerm(urlParam)
            console.log('urlParam -- ', urlParam)
            search(urlParam, 1)
    }, []);

    async function search(hostname, pageNumber = 1){
        await axios.get(BASEURL + APIENDPOINTS.searchReviews, //+'?page='+pageNumber, 
        {
            params: {
                website_hostname: hostname,
                page: pageNumber
            }
        })
        .then(response => {
            let data = response.data;

            if(data.status !== 200){
                // console.log('400 ');
            }else{
                setAllReviews(JSON.parse(JSON.stringify(data?.reviews?.data)));
                setAllPagination(data.allPagination);
               
                setSearchTerm(data.reviews.data[0].website_hostname)
            }
            setLoading(false)
        })
        .catch((error) => {
            setLoading(false)
            console.log('ERROR:: ', error)
        });
    };


    // if (loading) {
    //     return <div>Loading...</div>;
    // }

    return(
        <Container>            
           <Row>
                <Col xs={12} md={12}><h2>Here are your results for "{searchTerm}"</h2></Col>
            </Row>

            {   
                allReviews && allReviews.length > 0 ?
                    allReviews
                    .map((review, index) => {
                        return (
                            <>
                            <Row className="mt-4 pb-4">
                                <Col xs={12} md={12} ><h5>
                                    <a href={'reviews?hn='+btoa(review.website_hostname)}>{review.website_hostname}</a>
                                </h5></Col>
                                <Col xs={12} md={12} className="pt-0 pb-0">
                                    <span style={{display: 'inline-block'}} >
                                        <ReactStars
                                            count={5}
                                            size={14}
                                            value={review.review_stars}
                                            isHalf={false}
                                            isEdit={false}
                                            activeColor={COLOURS.reviewStars}
                                            emptyIcon={<i className="far fa-star"></i>}
                                            halfIcon={<i className="fa fa-star-half-alt"></i>}
                                            filledIcon={<i className="fa fa-star"></i>}
                                            fullIcon={<i className="fa fa-star"></i>}
                                        />
                                    </span>
                                    <span className="ms-4" style={{display: 'inline-block'}} >
                                        {allPagination} reviews
                                    </span>
                                    <span className="ms-4">
                                        <a href="#">Write a review</a>
                                    </span>
                                </Col>
                            </Row>
                            </>
                        )
                    })
                :
                <p className="mt-3">No reviews found.</p>           
            } 
        </Container>
    )
}

var styles = {

}

export default AllReviews;