import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppContext } from './../AppContext';
import {Form, Button, Row, Col, Container} from 'react-bootstrap';
import axios from "axios";
import {
    BASEURL,
    APIENDPOINTS,
} from "../utils/Constants";

export default function ResetPassword(props){
    let navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [successMessage, setSuccessMessage] = React.useState(''); 
    const [errorMessage, setErrorMessage] = React.useState(''); 
    const [password, setPassword] = React.useState(''); 
    const [repeatPassword, setRepeatPassword] = React.useState(''); 

    useEffect(() => {
       
    }, []);

    const handleSubmitForm = (e) => {
        e.preventDefault();
        if(password !== repeatPassword){
            setErrorMessage("New Password and Repeat New Password don't match");
            return
        }else{
            setErrorMessage('');
        }

        if(password.length < 6){
            setErrorMessage("This Password field is required. The password should be at least 6 characters.");
            return
        }else{
            setErrorMessage('');
        }

        axios.post(BASEURL+APIENDPOINTS.changePassword, {
            userId: searchParams.get("id"),
            password: password
        })
        .then(response => {
            let data = response.data;

            if(data.status != 200){
                console.log(data?.message);
            }else{
                navigate('/login')
            }
        })
    }
    
    return(
        <Container>            
            <Row className="mt-5">
                <Col xs={12} md={12}>
                    <h5>Reset Password</h5>
                </Col>
            </Row> 
            <Row className="mt-4">
                <Col xs={12} md={6}>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicfName">
                            <Form.Label>New Password</Form.Label>
                            <Form.Control type="password" name="password" placeholder="******" 
                            onChange={(e) => {setPassword(e.target.value) }}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicfName">
                            <Form.Label>Confirm New Password</Form.Label>
                            <Form.Control type="password" name="confirmPassword" placeholder="******" 
                            onChange={(e) => {setRepeatPassword(e.target.value) }}
                            />
                        </Form.Group>

                        <small className="errorMessage mb-3" 
                            style={{display: errorMessage ? 'block' : 'none'}}>
                            {errorMessage}
                        </small>
                        <small className="successMessage mb-3" 
                            style={{display: successMessage ? 'block' : 'none'}}>
                            {successMessage}
                        </small>

                        <Button variant="primary" type="button" onClick={handleSubmitForm}>
                            Submit
                        </Button>
                    </Form>       
                </Col>
            </Row> 
        </Container>
    )
}

var styles = {

}