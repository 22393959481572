import React from "react";
import {Button, Row, Col} from 'react-bootstrap';
import ReactStars from "react-rating-stars-component";
import {
    COLOURS
} from "../utils/Constants";

function ReviewStats(props) {
    return(
        <Row className="pt-1 pb-5">
            <Col xs={4} md={4}>
                <h4>{props.websiteHostname}</h4>
                <a target="_blank" href={props.websiteHref}>{props.websiteHref}</a>
            </Col>
            <Col xs={4} md={4}>
                <div className="w-100">
                    <span style={{float: 'left'}}>
                        <ReactStars
                            count={5}
                            size={24}
                            value={4}
                            isHalf={false}
                            activeColor={COLOURS.reviewStars}
                            edit={false}
                        /> 
                    </span>
                    <h4 className="pt-1 text-secondary ms-2">(4.5)</h4>
                </div>
                <div className="w-100" style={{float: 'left'}}>
                    <Button variant="" className="outline-secondary p-0">
                        <u>{props.totalReviewsNum} Total Reviews</u>
                    </Button>
                </div>
            </Col>
            <Col xs={4} md={4} style={{textAlign: 'right'}} >
                <Button variant="primary" type="button"
                    onClick={() => props.openReviewScreen(false)}>
                    Leave a review
                </Button>
            </Col>
        </Row>
    )
}

export default ReviewStats;