import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from './../AppContext';
import {Form, Button, Row, Col, Container} from 'react-bootstrap';
import ReactStars from "react-rating-stars-component";
import { useSearchParams } from "react-router-dom";
import Pagination from "react-js-pagination";
import axios from "axios";
import {
    BASEURL,
    APIENDPOINTS,
    errors,
    COLOURS
} from "../utils/Constants";

import UserReview from "./UserReview";
import ReviewStats from "./ReviewStats";

function MyReviews(props) {
    let navigate = useNavigate();
    const {context, setContext} = useContext(AppContext);
    const [allReviews, setAllReviews] = React.useState([]);
    const [paginationData, setPaginationData] = React.useState({}); 
    const [isAuthenticated, setIsAuthenticated] = React.useState('no'); 
    const [isReviewed, setIsReviewed] = React.useState(false);
    const [userId, setUserId] = React.useState('');
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {

        if(localStorage.getItem('userDetails') && JSON.parse(localStorage.getItem('userDetails'))?.isAuthenticated === 'yes'){
            let user_id = JSON.parse(localStorage.getItem('userDetails')).userId;
            setUserId(user_id);
            getMyReviews(user_id)
        }else{
            navigate("/login")
        }
    }, [])

    const getMyReviews = async (user_id, pageNumber = 1) => {
        await axios.get(BASEURL + APIENDPOINTS.getMyReviews, //+'?page='+pageNumber, 
        {
            params: {
                user_id: user_id,
                page: pageNumber
            }
        })
        .then(response => {
            let data = response.data;

            if(data.status !== 200){
                // console.log('400 ');
            }else{
                setAllReviews(JSON.parse(JSON.stringify(data?.reviews?.data)));
                setPaginationData({
                    current_page: data.reviews.current_page,
                    from: data.reviews.from,
                    to: data.reviews.to,
                    per_page: data.reviews.per_page,
                    next_page_url: data.reviews.next_page_url,
                    first_page_url: data.reviews.first_page_url,
                    allPagination: data.allPagination
                });
            }
            setLoading(false)
        })
        .catch((error) => {
            setLoading(false)
            console.log('ERROR:: ', error)
        });
    };

    if (loading) {
        return <div class="container mt-5">Loading...</div>;
    }

    return(
        <Container>            
            <Row className="mt-4">
                <Col xs={12} md={12}>
                    <h5>My Reviews</h5>
                </Col>
            </Row> 

            {   
                allReviews && allReviews.length > 0 ?
                    allReviews.map((review, index) => {
                        return ( 
                            <div className="border-bottom pt-2 pb-2">
                                <UserReview 
                                    profile_pic={review?.profile_pic}
                                    name={review.name}
                                    review_stars={review.review_stars}
                                    updated_at={review.updated_at}
                                    review_comment={review.review_comment}
                                    user_id={review.user_id}
                                    logged_user_id={userId}
                                    is_reviewed={isReviewed}
                                    reviewId={review.id}
                                    isAuthenticated={(context?.isAuthenticated === 'yes' || isAuthenticated === 'yes' ) ? 'yes' : 'no'}
                                />
                            </div>
                        )
                    })
                :
                <p className="mt-3">No reviews found.</p>           
            }   
            {   
                paginationData && paginationData.allPagination > paginationData.per_page ?
                <div className="mt-3">
                    <Pagination
                        activePage={paginationData.current_page}
                        itemsCountPerPage={paginationData.per_page}
                        totalItemsCount={paginationData.allPagination}
                        pageRangeDisplayed={5}
                        onChange={(pageNumber) => {
                            getMyReviews(userId, pageNumber)
                        }}
                        itemClass="page-item"
                        linkClass="page-link"
                    />
                </div>
                : null       
            }
        </Container>
    )
}

var styles = {

}

export default MyReviews;