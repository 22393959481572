import React, { useContext, useEffect, useState } from "react";
import { AppContext } from './../AppContext';
import {Form, Button, Row, Col, Dropdown, Modal} from 'react-bootstrap';
import ReactStars from "react-rating-stars-component";
import ShowMoreText from "react-show-more-text";
import axios from "axios";
import {
    capitalizeFirstLetter
} from "../utils/Common";

import {
    BASEURL,
    APIENDPOINTS,
    ENDPOINT,
    COLOURS
} from "../utils/Constants";

export default function UserReview(props) {   
    const { context, dispatchUserEvent } = useContext(AppContext);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const executeOnClick = (isExpanded) => {
        // console.log(isExpanded);
    };

    const flagReview = (review_id, type) => {
        axios.post(BASEURL+APIENDPOINTS.flagReview, {
            review_id: review_id,
            user_id: props.user_id,
            flag_type: type
        })
        .then(response => {
            let data = response.data;

            if(data.status !== 200){
                console.log('ERROR:: ', data?.message)
                return
            }else{
                // console.log(data)
            }
        })
        .catch((error) => {
            console.log('ERROR:: ', error)
        });
    };

    return(   
        <>
        <Row 
            className={(props.logged_user_id == props.user_id ? 'pb-2 ' : 'mt-3 border-bottom pb-2')}
            key={props.reviewId}
        >
            <Col xs={1} md={1}>
                <img
                    src={props.profile_pic ? `${ENDPOINT}/uploads/${props.profile_pic}` : "images/default-profile-pic.jpeg"}
                    height="30"
                    width="30"
                    className="border border-light rounded-circle"
                    alt="G-Reviews"
                />  
            </Col>
            <Col xs={8} md={8}>
                <h6 className="pt-2 ms-2 font-weight-bold">{capitalizeFirstLetter(props.name)}</h6>
            </Col>
            <Col xs={3} md={3} style={{textAlign: 'right',display: props.isAuthenticated === 'yes' ? 'block' : 'none' }}>
                <Dropdown>
                    <Dropdown.Toggle variant="none" id="dropdown-custom-components">
                        <i className="fa fa-ellipsis-v"></i>
                    </Dropdown.Toggle>
                    { props.logged_user_id == props.user_id ?
                    <Dropdown.Menu>
                        <Dropdown.Item href={void(0)} onClick={(e) => handleShow()}>
                            Delete
                        </Dropdown.Item>
                    </Dropdown.Menu>
                    :
                    <Dropdown.Menu>
                        <Dropdown.Item href={void(0)} onClick={(e) => flagReview(props.reviewId, 'spam')}>Flag as spam</Dropdown.Item>
                        <Dropdown.Item href={void(0)} onClick={(e) => flagReview(props.reviewId, 'inappropriate')}>Flag as inappropriate</Dropdown.Item>
                    </Dropdown.Menu>
                    }
                </Dropdown>
            </Col>

            <Col xs={12} md={12} className="mt-0 pt-0">
                <div className="d-flex flex-row starsContainer">
                    <ReactStars
                        count={5}
                        size={14}
                        value={Number(props.review_stars)}
                        isHalf={false}
                        activeColor={COLOURS.reviewStars}
                        edit={false}
                    />
                    <p style={{fontSize: '12px'}} className="text-secondary ms-3 pt-1">
                        {new Date(props.updated_at).toLocaleDateString()}
                    </p>
                </div>
            </Col>
            {(props.review_comment && props.review_comment.length > 0) && <Col xs={12} md={12}>
                <ShowMoreText
                    lines={2}
                    more="Show full review"
                    less="Show less"
                    className="content-css"
                    anchorClass="my-anchor-css-class"
                    onClick={executeOnClick}
                    expanded={false}
                    truncatedEndingComponent={" "}
                >
                    {capitalizeFirstLetter(props.review_comment)}
                </ShowMoreText>
            </Col>}
        </Row>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Cancel
            </Button>
            <Button variant="primary" onClick={() => {props.deleteReview(props.reviewId); handleClose();}}>
                Delete review
            </Button>
            </Modal.Footer>
        </Modal>
        </>
    )
}
