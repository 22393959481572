export const isValidUrl = (string) => {
    let url;
  
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }

    return url.protocol === "http:" || url.protocol === "https:";
};

export const capitalizeFirstLetter = (string) => {
  if(!string || string.length === 0)
    return undefined
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const asyncLocalStorage = {
  setItem: function (key, value) {
      return Promise.resolve().then(function () {
          localStorage.setItem(key, value);
      });
  },
  getItem: function (key) {
      return Promise.resolve().then(function () {
          return localStorage.getItem(key);
      });
  }
};
