import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from './../AppContext';
import {Form, Button, Row, Col, Container, Modal} from 'react-bootstrap';
import axios from "axios";
import {
    BASEURL,
    APIENDPOINTS,
} from "../utils/Constants";

function Settings(props) {
    let navigate = useNavigate();
    const {context, setContext} = useContext(AppContext);
    const [currenPassword, setCurrenPassword] = React.useState(''); 
    const [newPassword, setNewPassword] = React.useState(''); 
    const [repeatNewPassword, setRepeatNewPassword] = React.useState(''); 
    const [showPassNoMatch, setShowPassNoMatch] = React.useState(false); 
    const [successChanged, setSuccessChanged] = React.useState(false); 
    const [errorMessage, setErrorMessage] = React.useState(''); 
    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if(!localStorage.getItem('userDetails') 
            || JSON.parse(localStorage.getItem('userDetails'))?.isAuthenticated === 'no' 
            || !localStorage.getItem('userDetails').length === 0){
            navigate("/login")
        }
    }, []);

    const handleChangePass = (e) => {
        e.preventDefault();

        if(newPassword !== repeatNewPassword){
            setShowPassNoMatch(true)
            return
        }else{
            setShowPassNoMatch(false)
        }

        axios.post(BASEURL+APIENDPOINTS.updatePassword, {
            userId: JSON.parse(localStorage.getItem('userDetails')).userId,
            current_password: currenPassword,
            new_password: newPassword,
            new_confirm_password: repeatNewPassword
        })
        .then(response => {
            let data = response.data;

            if(data.status != 200){
                setSuccessChanged(false)
                setErrorMessage(data?.message)
                return
            }else{
                setSuccessChanged(true)
            }
        })
    }

    const handleDeleteAcc = (e) => {
        e.preventDefault();

        if(newPassword !== repeatNewPassword){
            setShowPassNoMatch(true)
            return
        }else{
            setShowPassNoMatch(false)
        }

        axios.post(BASEURL+APIENDPOINTS.deleteAccount, {
            userId: JSON.parse(localStorage.getItem('userDetails')).userId,
        })
        .then(response => {
            let data = response.data;

            if(data.status != 200){
                return
            }else{
                localStorage.removeItem('userDetails');
                window.open("/","_self");
            }
        })
    }

    return(
        <Container>            
            <Row className="mt-4">
                <Col xs={12} md={12}>
                    <h5>Settings</h5>
                </Col>
            </Row> 
            <Row className="mt-4 pb-4">
                <Col xs={12} md={6}>
                    <h6>Change Password</h6>
                    <Form>
                        <Form.Group className="mb-3" controlId="oldPass">
                            <Form.Label>Old password</Form.Label>
                            <Form.Control type="password" 
                            onChange={(e) => {setCurrenPassword(e.target.value) }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="newPass">
                            <Form.Label>New password</Form.Label>
                            <Form.Control type="password"
                            onChange={(e) => {setNewPassword(e.target.value) }}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="repeatNewPass">
                            <Form.Label>Confirm new password</Form.Label>
                            <Form.Control type="password"
                            onChange={(e) => {setRepeatNewPassword(e.target.value) }}
                            />
                            <small className="errorMessage" 
                                style={{display: showPassNoMatch ? 'block' : 'none'}}>
                                Confirm Password don't match the new password! 
                            </small>
                            <small className="successMessage" 
                                style={{display: successChanged ? 'block' : 'none'}}>
                                Password changed succesfully 
                            </small>
                            <small className="errorMessage" 
                                style={{display: errorMessage ? 'block' : 'none'}}>
                                {errorMessage}
                            </small>
                        </Form.Group>

                        <Button variant="primary" type="button" onClick={handleChangePass}>
                            Update
                        </Button>
                    </Form>       
                </Col>
            </Row> 

            <Row className="mt-4 border-top pt-5">
                <Col xs={12} md={6}>
                    <h5>Delete your account</h5>
                    <Button variant="danger" type="button" onClick={handleShow}>
                        Delete
                    </Button>
                </Col>
            </Row>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Account Deletion</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>By deleting your account all your data will be removed.</p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancel</Button>
                    <Button variant="danger" onClick={handleDeleteAcc}>Confirm & Delete</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}

var styles = {

}

export default Settings;