export const BASEURL = "https://api.g-reviews.com/api/v1/";
export const ENDPOINT = "https://api.g-reviews.com";

export const APIENDPOINTS = {
    login: 'login',
    register: 'register',
    logout: 'logout',
    verifyEmail: 'verify-email',
    resetpassword: 'reset-password',
    forgotPassword: 'forgot-password',
    forgotPasswordGenerateCode: 'forgot-password/generate-code',
    forgotPasswordVerifyEmail: 'forgot-password/verify-email',
    storeReview: 'store-review',
    updateReview: 'update-review',
    deleteReview: 'delete-review',
    getAllReviews: 'all-reviews',
    searchReviews: 'search-reviews',
    checkUserReviewed: 'check-user-reviewed',
    flagReview: 'flag-review',
    updateUser: 'update-user',
    getUser: 'get-user',
    updatePassword: 'update-password',
    deleteAccount: 'delete-account',
    getMyReviews: 'get-my-reviews',
    activateAccount: 'activate-account',
    sendVerificationEmail: 'send-verification-email',
    changePassword: 'reset-password'
};

export const errors = {
    requiredField: 'This field is required',
    validationPassReg: 'This field is required. The password should be at least 6 characters.'
};

export const COLOURS = {
    reviewStars: '#FFBD00'
};
